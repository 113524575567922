








































import { Component, Vue, ModelSync, Prop, Watch } from "vue-property-decorator";
import { ComboBoxItem } from "@/interfaces";

@Component
export default class SelectActivator extends Vue {
    @ModelSync("activatedValue", "change", { type: Boolean })
    activated!: boolean;

    @Prop({ type: Boolean, required: false, default: true })
    enabled!: boolean;

    @Prop({ type: Number, required: true, default: 0 })
    defaultOption!: number;

    @Prop({ type: Array, required: false, default: null })
    options!: ComboBoxItem[] | null;

    @Prop({ type: String, required: false, default: "" })
    labelText!: string;

    @Prop({ type: String, required: false, default: "" })
    subheaderText!: string;

    /**
     * Force deactivate checkbox if not enabled
     */
    @Watch("enabled")
    onEnabledChaged() {
        if (!this.enabled) this.activated = false;
    }

    handleChange(value: number) {
        this.$emit("on-selected", value);
    }
}
